import '../styles/index.scss';
import '../styles/header.scss';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

var headerControls = function () {

	$('nav').on('transitionend', function () {

		var $this = $(this);
		var isNotActive = !$this.parent().hasClass('active');

		if (isNotActive) {
			$this.removeClass('reveal');	
		}

	});

	$('header').on('click', function (ev) {

		var $header = $(this);
		var target = ev.target;
		var navClicked = ev.target.parentNode.tagName === "LI";
		var navActive = $header.hasClass('active');

		if (navClicked && !navActive) {
			return;
		}
		

		if (target.id === 'hamburgerMenu') {

			$header.toggleClass('active');
			$header.find('nav').addClass('reveal');

		} else if (target.tagName === "A") {

			ev.preventDefault();

			var headerHeight = $header.find('.wrapper').innerHeight();
			var dest = $(target).attr('href');
			var destTop = $(dest).offset().top;


			if (navClicked){

				window.scrollTo(0, destTop - headerHeight + 1);
				$header.toggleClass('active');

			} else {

				$('html, body').animate({
					scrollTop:  0
				}, 500);

				if (navActive) {
					$header.removeClass('active');
				}

			}
		}

	});
};


var scrollButton = function () {

	$('.scrollButton').on('click', function (ev) {

		ev.preventDefault();

		var target = ev.target;
		var headerHeight = $('header').find('.wrapper').innerHeight();
		var dest = $(target).attr('href');
		var destTop = $(dest).offset().top;

		$('html, body').animate({
				scrollTop: destTop - headerHeight + 1 
			}, 500);
	});
};

$( document ).ready(function() {
    headerControls();
    scrollButton();
});

